/*!
 * PatternFly Elements: CpSearchAutocomplete 0.0.9
 * @license
 * Copyright 2020 Red Hat, Inc.
 * 
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 * 
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 * 
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE
 * SOFTWARE.
 * 
*/

import PFElement from "../../../@patternfly/pfelement/dist/pfelement.js"; // | umd
import "../../../@patternfly/pfe-autocomplete/dist/pfe-autocomplete.js"; // | umd

// /node_modules
//   @patternfly
//     pfelement
//       dist
//         pfelement.js
//   @cpelements
//     cp-search-autocomplete
//       dist
//         cp-search-autocomplete.js

class CpSearchAutocomplete extends PFElement {
  static get version() {
    return "0.0.9";
  }

  get html() {
    return `<style>:host{display:block}:host([hidden]){display:none}pfe-autocomplete input{--pfe-theme--ui--element--size:17px}pfe-autocomplete ul li:hover{color:#252525;background-color:#ececec}
/*# sourceMappingURL=cp-search-autocomplete.min.css.map */
</style><pfe-autocomplete id="static" debounce="300"> 
    <input>
</pfe-autocomplete>`;
  }
  static get tag() {
    return "cp-search-autocomplete";
  }

  static get properties() {
    return {
      path: {
        title: "JSON Data path",
        type: String
      },
      query: {
        title: "Input value",
        type: String,
        observer: "_queryChanged"
      },
      numItems: {
        title: "Number of items in dropdown",
        type: Number,
        default: 5
      },
      placeholder: {
        title: "Input placeholder text",
        type: String,
        default: "Enter your search term",
        observer: "_placeholderChanged"
      }
    };
  }

  get templateUrl() {
    return "cp-search-autocomplete.html";
  }

  get styleUrl() {
    return "cp-search-autocomplete.scss";
  }

  static get PfeType() {
    return PFElement.PfeTypes.Container;
  }

  constructor() {
    super(CpSearchAutocomplete, { type: CpSearchAutocomplete.PfeType });
    this._input = this.shadowRoot.querySelector("input");
    this._data = null;

    this._searchAutocomplete = this.shadowRoot.querySelector(
      "pfe-autocomplete"
    );

    this._searchAutocomplete.addEventListener("pfe-search-event", e =>
      console.log(e.detail.searchValue)
    );
  }

  connectedCallback() {
    super.connectedCallback();
    this._fetchData();
  }

  _queryChanged(oldValue, newValue) {
    this._input.value = newValue;
  }

  _placeholderChanged(oldValue, newValue) {
    this._input.setAttribute("placeholder", newValue);
    this._input.setAttribute("aria-label", newValue);
  }

  _fetchData() {
    const path = this.getAttribute("path");
    if (!path) {
      return;
    }

    fetch(path)
     .then((response) => {
      if (response.status === 404) {
        console.warn(`${CpSearchAutocomplete.tag}:no-data`)
        throw new Error("No data available");
      }
      return response.json();
      })
     .then((data) => this._initAutocomplete(data))
    .catch((error) => console.error(error))
  }

  _initAutocomplete(newData) {
    this._data = newData;
    this._searchAutocomplete.autocompleteRequest = (params, callback) => {
      const regx = new RegExp("^" + params.query, "i");
      const matchingItems = this._data
        .filter(function(item) {
          return regx.test(item);
        })
        .slice(0, this.numItems);
      callback(matchingItems);
    };
  }
}

PFElement.create(CpSearchAutocomplete);

export default CpSearchAutocomplete;
